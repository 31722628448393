import { useEffect, useState } from 'preact/hooks';

import { getPaymentAccount } from '../../api/dungeon-draws/getPaymentAccount';
import { notifyError } from '../../helpers/notifyError';

type Props = { isArtist: boolean };

type PaymentAccountState = 'unknown' | 'requiresSetup' | 'incompleteSetup' | 'complete';
export function GlobalAlerts({ isArtist }: Props) {
  const [paymentAccountState, setPaymentAccountState] = useState<PaymentAccountState>('unknown');

  useEffect(() => {
    if (isArtist) {
      getPaymentAccount().then((response) => {
        if (response.ok) {
          const { data } = response;
          if (!data.hasAccount) {
            setPaymentAccountState('requiresSetup');
          } else if (data.accountHasRequirements) {
            setPaymentAccountState('incompleteSetup');
          } else {
            setPaymentAccountState('complete');
          }
        } else {
          notifyError(new Error(response.errors[0]));
        }
      });
    }
  }, [isArtist]);

  return (
    <>
      {paymentAccountState === 'requiresSetup' && (
        <div className="w-full p-4 bg-beholder-pink text-center">
          TODO: You need to <a href="/">set up payments</a> before you can receive commissions.
        </div>
      )}
      {paymentAccountState === 'incompleteSetup' && (
        <div className="w-full p-4 bg-beholder-pink text-center">
          TODO: Your payment setup is incomplete.
        </div>
      )}
    </>
  );
}
